import { makeStyles, tokens } from "@fluentui/react-components";
import { useState } from "react";
import { ConversationMessages } from "./conversationMessages/ConversationMessages";
import { MessageBar } from "./MessageBar";

const useStyles = makeStyles({
    chat: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between"
    },
    messages: {
        overflowY: "hidden",
        height: "100%",
        position: "relative",
        backgroundColor: tokens.colorNeutralBackground2
    },
    messageBar: {
        padding: "20px 20px 10px 20px",
        backgroundColor: tokens.colorNeutralBackground2,
        flexWrap: 'nowrap'
    }
});

export const Chat = (): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [scroll, setScroll] = useState(0)
    const styles = useStyles();

    const handleScroll = (event: any) => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        setScroll(scrollHeight - scrollTop - clientHeight)
    }

    return <div className={styles.chat}>
        <div
            onScroll={handleScroll}
            className={styles.messages}
        >
            <ConversationMessages isMessageLoading={isLoading} scroll={scroll} setMessageLoadingState={setIsLoading} />
        </div>
        <div className={styles.messageBar}>
            <MessageBar setIsMessageLoading={setIsLoading} />
        </div>
    </div>
}