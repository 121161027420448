import { Button, Link } from "@fluentui/react-components";
import { VirtualizerScrollView } from '@fluentui/react-components/unstable';
import { Add16Regular } from "@fluentui/react-icons";
import { Constants, ConstantValues } from "common/constants";
import { IGroup } from "models/IGroup";
import { IUser } from "models/IUser";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { deleteConversation, getConversations, getGroups, getMessagesOfConversation, getUsers, setConversationToPin, setConversationToSharing } from "../../api";
import { setCurrentConversation } from "../../redux/conversations";
import { setGroups } from "../../redux/groups";
import { setCurrentMessages } from "../../redux/messages";
import { setCurrentPrompt } from "../../redux/selectedPrompt";
import { setTagsForKey } from "../../redux/tags";
import { setTemporaryChatOpen } from "../../redux/temporaryChat";
import { setUsers } from "../../redux/users";
import { ProgressBarIndeterminate } from "../ProgressBar";
import { ActionButtons } from "./actionButtons/ActionButtons";
import { ConversationCard } from "./conversationCard/ConversationCard";
import { useStyles } from "./Conversations.styles";
import { ConversationsSearch } from "./conversationsSearch/ConversationsSearch";

export const Conversations = (): JSX.Element => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ascending, setAscending] = useState<boolean>(false);
    const [pinned, setPinned] = useState<boolean>(false);
    const [onlySharedConversations, setSharedConversations] = useState<boolean>(false);
    const [searchText, setSearchText] = useState(ConstantValues.EMPTY_STRING);
    const [filterCapabilities, setFilterCapabilities] = useState<string[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const currentlySelectedConversation = useSelector((state: RootState) => state.currentConversationSetter.value);
    const currentUser: IUser = useSelector((state: RootState) => state.currentUserSlice.value);
    const users: IUser[] = useSelector((state: RootState) => state.usersSlice.value);

    useEffect((): void => {
        if (!users.length) {
            const fetchUsers = async () => {
                const data = await getUsers();
                dispatch(setUsers(data));
            };
            fetchUsers();
        }
    }, [dispatch, users]);

    useEffect((): void => {
        const fetchGroups = async () => {
            const data: IGroup[] = await getGroups();
            dispatch(setGroups(data));
        };
        fetchGroups();
    }, [dispatch]);

    const { data: conversations, isLoading, isError, refetch } = useQuery({
        queryKey: ['conversations', onlySharedConversations, pinned, ascending, searchText, filterCapabilities],
        queryFn: async () => {
            //@ts-ignore
            return await getConversations({ onlySharedConversations, pinned, ascending, searchText, filterCapabilities });
        },
    });

    const selectConversation = async (conversation: any): Promise<void> => {
        dispatch(setCurrentConversation(conversation));
        dispatch(setTemporaryChatOpen(false));
        dispatch(setCurrentMessages([]));
        dispatch(setCurrentPrompt({ key: ConstantValues.CHAT, prompt: null }));
        const data = await getMessagesOfConversation({ conversationId: conversation.id });
        dispatch(setCurrentMessages(data));
        dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: [] }));
    };

    const removeConversation = async (conversation: any) => {
        try {
            setIsDeleting(true);
            await deleteConversation({ conversationId: conversation.id });
        } catch (error) {
            console.log(error);
        }
        finally {
            refetch();
            dispatch(setCurrentMessages([]));
            dispatch(setCurrentConversation(null));
            dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: [] }));
            setIsDeleting(false);
        }
    };

    const setConversationPinned = async (conversation: any) => {
        const conversationToUpdate = { ...conversation, pinned: !conversation.pinned };
        await setConversationToPin({ conversation: conversationToUpdate });
        refetch();
    };

    const setConversationSharing = async (conversation: any) => {
        await setConversationToSharing({ conversation });
        refetch();
    };

    const changeAscending = (): void => setAscending(!ascending);

    const filterByCapabilities = (selectedCapabilities: string[]): void => setFilterCapabilities(selectedCapabilities);

    const resetConversationAndMessages = () => {
        dispatch(setCurrentMessages([]));
        dispatch(setCurrentConversation(null));
        dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: [] }));
        dispatch(setCurrentPrompt({ key: ConstantValues.CHAT, prompt: null }));
    };

    const orderByPinned = () => setPinned(!pinned);

    const getOnlySharedConversation = () => setSharedConversations(!onlySharedConversations);

    const onEnterPress = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            setSearchText(e.target.value);
        }
    };

    const resetSearchIfInputIsEmpty = (e: any) => {
        if (!e.target.value) {
            setSearchText(ConstantValues.EMPTY_STRING);
        }
    };

    if (isLoading || isDeleting) return <div className={styles.container}><ProgressBarIndeterminate color={'white'} /></div>;

    if (isError || !Array.isArray(conversations)) return <></>;

    return (
        <div className={styles.container}>
            <ConversationsSearch onEnterPress={onEnterPress} resetSearchIfInputIsEmpty={resetSearchIfInputIsEmpty} />
            <ActionButtons
                filterByCapabilities={filterByCapabilities}
                orderByPinned={orderByPinned}
                getOnlySharedConversation={getOnlySharedConversation}
                onlySharedConversations={onlySharedConversations}
                changeAscending={changeAscending}
                pinned={pinned}
                ascending={ascending}
            />
            <div className={styles.conversationsWrapper}>
                <VirtualizerScrollView
                    itemSize={10}
                    numItems={conversations.length}
                >
                    {(index: number) => (
                        <div key={index} style={{ padding: '5px 0px 0px 0px' }}>
                            <ConversationCard
                                conversation={conversations[index]}
                                currentlySelectedConversation={currentlySelectedConversation}
                                currentUser={currentUser}
                                selectConversation={selectConversation}
                                setConversationPinned={setConversationPinned}
                                setConversationSharing={setConversationSharing}
                                removeConversation={removeConversation}
                            />
                        </div>
                    )}
                </VirtualizerScrollView>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerButtons}>
                    <Link className={styles.footerLink} onClick={() => navigate('/help')}>Help</Link>
                    <a
                        className={styles.footerLink}
                        href={Constants.ISSUE_URL}
                        onMouseOver={(e) => (e.currentTarget.style.textDecoration = "none")}
                        onMouseOut={(e) => (e.currentTarget.style.textDecoration = "underline")}
                    >
                        Report an issue
                    </a>
                </div>
                <div className={styles.centerFooterButton}>
                    <Button
                        className={styles.newConversationButton}
                        appearance="primary"
                        icon={<Add16Regular />}
                        onClick={resetConversationAndMessages}
                    >
                        New Conversation
                    </Button>
                </div>
            </div>
        </div>
    );
};