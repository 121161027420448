import { makeStyles, tokens } from "@fluentui/react-components";
import { NHColors } from "utils/generalUtils";

export const useStyles = makeStyles({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    messageContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "50px 5rem 5px 5rem",
        backgroundColor: tokens.colorNeutralBackground2,
    },
    messageWrapper: {
        flex: 1,
        overflowY: "auto",
    },
    noMessagesContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
    },
    messageContent: {
        position: "relative",
        padding: "10px 20px",
        wordBreak: "break-word",
        "& code": {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "100%",
            display: "block",
            backgroundColor: tokens.colorNeutralBackground1,
            padding: "8px",
            borderRadius: "4px",
            overflowX: "auto",
        },
        "& pre": {
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            maxWidth: "100%",
            backgroundColor: tokens.colorNeutralBackground1,
            padding: "8px",
            borderRadius: "4px",
            overflowX: "auto",
        },
    },
    messageDetails: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "10px",
    },
    assistantMessage: {
        alignItems: "start",
    },
    userMessage: {
        alignItems: "end",
    },
    editInput: {
        width: "60%",
    },
    floatingRewriteButton: {
        backgroundColor: NHColors.primary,
        position: "fixed",
        zIndex: 100,
        height: "25px",
        width: "50px",
    },
    tagWrapper: {
        display: "flex",
        gap: "5px",
        flexWrap: "wrap",
    },
    fileCard: {
        height: "fit-content",
    },
    dateStyle: {
        padding: "0px 0px 0px 5px",
        color: tokens.colorNeutralForeground3,
    },
    actionsWrapper: {
        display: "flex",
        gap: "5px",
    },
    captionWrapper: {
        textAlign: "end",
    },
});