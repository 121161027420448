import { Body1Strong, Checkbox, Tree, TreeItem, TreeItemLayout } from "@fluentui/react-components";
import { AddSquare16Regular, SubtractSquare16Regular } from "@fluentui/react-icons";
import { getCategories } from "api";
import { ProgressBarIndeterminate } from "components/ProgressBar";
import { ITag } from "models/ITag";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setTagsForKey } from "../../redux/tags";
import { ICategoryTagTreeProps } from "./CategoryTagTree.types";

export const CategoryTagTree = (props: ICategoryTagTreeProps) => {
    const { data: categories, isLoading: areCategoriesLoading, isError: categoriesError } = useQuery('categories', async () => {
        const categories = await getCategories(true);
        return categories;
    }, { refetchOnWindowFocus: false });

    const treeRef = useRef<HTMLDivElement>(null);
    const [openItems, setOpenItems] = useState<Array<any>>([]);
    const currentTags = useSelector((state: RootState) => state.currentTagsSlice.tags[props.dictKey] || []);
    const dispatch = useDispatch();

    const tagIds = currentTags?.length
        ? currentTags.reduce((acc: any, tag: ITag) => {
            acc.push(tag.id);
            return acc;
        }, [])
        : [];

    const handleClickOutside = (event: MouseEvent) => {
        if (treeRef.current && !treeRef.current.contains(event.target as Node)) {
            setOpenItems([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const selectTopic = (topic: any) => () => {
        const updatedTags = tagIds.includes(topic.id)
            ? currentTags.filter((tag: ITag) => tag.id !== topic.id)
            : [...currentTags, topic];

        dispatch(setTagsForKey({ key: props.dictKey, tags: updatedTags }));
    };

    const handleOpenChange = (event: any, data: any) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenItems((curr: any) =>
            data.open
                ? [...curr, data.value]
                : curr.filter((value: any) => value !== data.value)
        );
    };

    if (areCategoriesLoading) {
        return <ProgressBarIndeterminate />;
    }

    if (categoriesError || !Array.isArray(categories)) {
        return <></>;
    }

    return (
        <div ref={treeRef} >
            <Tree aria-label="Expand Icon" openItems={openItems} onOpenChange={handleOpenChange}>
                <TreeItem itemType="branch" value="all">
                    <TreeItemLayout expandIcon={openItems.includes("all") ? <SubtractSquare16Regular /> : <AddSquare16Regular />}>
                        <Body1Strong>Knowledge Components</Body1Strong>
                    </TreeItemLayout>
                    <Tree>
                        {categories.map((category, index: number) => (
                            <Tree key={index} aria-label="Expand Icon" openItems={openItems} onOpenChange={handleOpenChange}>
                                <TreeItem itemType="branch" value={category.id} >
                                    <TreeItemLayout style={{ paddingLeft: "10px" }} expandIcon={openItems.includes(category.id) ? <SubtractSquare16Regular /> : <AddSquare16Regular />}>
                                        <Body1Strong style={{ display: 'flex' }}>{category.title}</Body1Strong>
                                    </TreeItemLayout>
                                    <Tree>
                                        {category.tags.map((topic: any, index: number) => (
                                            <TreeItem key={index} itemType="leaf" onClick={selectTopic(topic)}>
                                                <TreeItemLayout style={{ paddingLeft: "15px", display: "flex", textAlign: 'initial' }}>
                                                    <Checkbox onChange={selectTopic(topic)} checked={tagIds.includes(topic.id)} />
                                                    {topic.name}
                                                </TreeItemLayout>
                                            </TreeItem>
                                        ))}
                                    </Tree>
                                </TreeItem>
                            </Tree>
                        ))}
                    </Tree>
                </TreeItem>
            </Tree>
        </div>
    );
};