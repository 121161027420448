import { useMsal } from "@azure/msal-react";
import {
    Button,
    Divider,
    Drawer,
    Image,
    makeStyles
} from "@fluentui/react-components";
import {
    Board20Filled,
    Board20Regular,
    Navigation24Regular,
    NotePin20Filled,
    NotePin20Regular,
    PersonCircle32Regular,
    bundleIcon
} from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { PropsWithChildren, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { NHColors } from "utils/generalUtils";
import { setCurrentConversation } from "../redux/conversations";
import { setCurrentMessages } from "../redux/messages";
import { setTagsForKey } from "../redux/tags";
import { setTemporaryChatOpen } from "../redux/temporaryChat";
import { ActionDialog } from "./actionDialog/ActionDialog";

const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const ChatIcon = bundleIcon(NotePin20Filled, NotePin20Regular);

const useStyles = makeStyles({
    main: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        rowGap: "0.3vh",
        padding: "0.5vh 0.5vh 0 0.5vh",
        maxHeight: 'none',
        backgroundColor: NHColors.primary,
        width: "350px",
        minWidth: "250px",
        transition: "width 0.3s ease, opacity 0.3s ease",
        opacity: 1,
        "&.collapsed": {
            width: "6vw",
            minWidth: "80px",
            opacity: 0.8,
        },
        "@media (max-width: 900px)": {
            width: "250px",
        },
    },
    collapsed: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        flexWrap: "wrap",
        width: "6vw"
    },
    button: {
        display: "flex",
        justifyContent: "start",
        color: 'white',
        ':hover': {
            color: NHColors.white,
            backgroundColor: NHColors.primaryOld,
            textDecoration: 'underline'
        }
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1vh",
        width: "100%",
        "@media (max-width: 900px)": {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    logoImage: {
        maxWidth: "60%",
        height: "auto",
    },
    uncollapsedButton: {
        marginBottom: '1vh',
        "--colorNeutralForeground2": 'white',
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        fontSize: "1.5vh",
        "@media (max-width: 900px)": {
            marginTop: "0.5vh",
        },
    },
})

interface INavigationPanelProps extends PropsWithChildren {
    isOpen?: boolean;
    setIsOpen?: (open: boolean) => void;
};

export const NavigationPanel = ({ isOpen: externalIsOpen, setIsOpen: externalSetIsOpen, children }: INavigationPanelProps): JSX.Element => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentSelectedConversation: any = useSelector((state: RootState) => state.currentConversationSetter.value)

    const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false);
    const { instance } = useMsal();

    const [internalIsOpen, setInternalIsOpen] = useState(true);

    const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
    const setIsOpen = externalSetIsOpen !== undefined ? externalSetIsOpen : setInternalIsOpen;

    const handleLogout = (): void => {
        setShowLogoutDialog(true);
    };

    const getLogoutActionDialog = (): JSX.Element => (
        <ActionDialog
            isVisible={showLogoutDialog}
            title={"Log Out Alert"}
            message={"Are you sure you want to log out?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onLogoutConfirmed}
            onDismiss={(): void => setShowLogoutDialog(false)}
        />
    );

    const onLogoutConfirmed = (): void => {
        localStorage.removeItem('token');
        instance.logoutRedirect().catch((e: any) => {
            console.error(e);
        });
    };

    const handleManagementClick = (): void => {
        dispatch(setTemporaryChatOpen(false));
        dispatch(setCurrentMessages([]));
        dispatch(setCurrentConversation(null));
        dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: [] }));

        if (currentSelectedConversation == null) {
            dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: [] }));
            dispatch(setTagsForKey({ key: ConstantValues.TEMPORARY_CHAT, tags: [] }));
        }

        navigate('/management');
    };

    return <>
        {!isOpen
            ? <Button
                appearance="transparent"
                style={{ zIndex: "1000", position: "fixed", top: "1vh", left: "1vh" }}
                onClick={() => setIsOpen(!isOpen)}>
                <Navigation24Regular />
            </Button>
            : <></>}
        {showLogoutDialog && getLogoutActionDialog()}
        <Drawer
            open={isOpen}
            type="inline"
            className={`${styles.main} ${!isOpen ? 'collapsed' : ConstantValues.EMPTY_STRING}`}
        >
            <div className={styles.headerContainer}>
                <Image
                    className={styles.logoImage}
                    src='/images/logos_header.png'
                />
                <Button
                    className={styles.uncollapsedButton}
                    appearance="transparent"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Navigation24Regular />
                </Button>
            </div>
            <Divider style={{ maxHeight: "2.4vh" }} />
            <div style={{
                display: "flex",
                gap: "0.5vh",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap"
            }}>
                <Button
                    className={styles.button}
                    onClick={handleManagementClick}
                    icon={<Dashboard />}
                    value="1"
                    appearance="subtle"
                    size="large"
                >
                    Management
                </Button>
                <Button
                    className={styles.button}
                    onClick={() => navigate('/')}
                    icon={<ChatIcon />}
                    value="1"
                    appearance="subtle"
                    size="large"
                >
                    Chat
                </Button>
            </div>
            {children}
            <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "auto", marginBottom: '1vh' }}>
                <Button
                    className={styles.button}
                    onClick={handleLogout}
                    icon={<PersonCircle32Regular />}
                    appearance="subtle"
                >
                    Logout
                </Button>
            </div>
        </Drawer>
    </>
}