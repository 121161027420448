import { IDropdownOption, Stack, StackItem } from "@fluentui/react";
import {
    Avatar,
    Button,
    InputOnChangeData,
    Label,
    MessageBar,
    SearchBox,
    SearchBoxChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableRowData,
    TagSize,
    tokens,
    Toolbar,
    ToolbarButton,
    Tooltip,
    useFocusableGroup,
    useTableColumnSizing_unstable,
    useTableFeatures
} from "@fluentui/react-components";
import { Add16Regular, DeleteRegular, EditRegular, ErrorCircle20Regular } from "@fluentui/react-icons";
import { ColumnActionsMenu } from "components/columnActionsMenu/ColumnActionsMenu";
import { IColumnActionsMenuProps } from "components/columnActionsMenu/ColumnActionsMenu.types";
import { InputSkeleton } from "components/InputSkeletons";
import { Paginator } from "components/paginator/Paginator";
import { TagWithAttachments } from "components/tagWithAttachments/TagWithAttachments";
import { SortingDirection } from "enums/SortingOrder";
import { FileWithId } from "models/FileWithId";
import { IColumnSizingOption } from "models/IColumnSizingOptions";
import React, { useEffect, useMemo, useState } from "react";
import { QueryClient, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { uploadFilesToStorage } from "utils/blobUtils";
import { updateCategory } from "../../api";
import { ConstantValues } from "../../common/constants";
import { ColumnTypes } from "../../enums/ColumnTypes";
import { ICategory } from "../../models/ICategory";
import { IFile } from "../../models/IFile";
import { ITableColumn } from "../../models/ITableColumn";
import { ITag } from "../../models/ITag";
import { clearAttachments, removeAttachment, setAttachments } from "../../redux/currentAttachments";
import { convertFileWithIdToIFile, currentAccount, IsNullUndefinedOrEmpty, NHColors, renderCellValue, validateFields } from "../../utils/generalUtils";
import { ActionDialog } from "../actionDialog/ActionDialog";
import { CellFactory } from "../cells/CellFactory";
import { containerClassName, searchBoxStyle, setGapBetweenHeadersAndDetailsList } from "./ManageTags.styles";
import { IManageTagsProps } from "./ManageTags.types";

const columnSizingOptions: Partial<Record<keyof ITag, IColumnSizingOption>> = {
    icon: { idealWidth: 50, minWidth: 50 },
    name: { idealWidth: 200, minWidth: 20 },
    description: { idealWidth: 200, minWidth: 200 },
    category: { idealWidth: 250, minWidth: 250 },
    attachments: { idealWidth: 350, minWidth: 350 },
    createdAt: { idealWidth: 175, minWidth: 175 },
    updatedAt: { idealWidth: 175, minWidth: 175 },
    userId: { idealWidth: 175, minWidth: 175 },
};

export const ManageTags = (props: IManageTagsProps) => {
    const columns: ITableColumn<ITag>[] = useMemo(() => [
        {
            columnId: "icon",
            displayValue: ConstantValues.EMPTY_STRING,
            renderHeaderCell: () => ConstantValues.EMPTY_STRING,
            renderCell: (): JSX.Element | null => null,
            compare: () => 0,
        },
        {
            columnId: "name",
            displayValue: "Name",
            renderHeaderCell: () => "Name",
            renderCell: (item: ITag) => item.name,
            compare: function (a: ITag, b: ITag): number {
                return a.name.localeCompare(b.name);
            },
            isEditableForEdit: true,
            isEditableForSave: true,
            type: ColumnTypes.SingleLineText,
            required: true
        },
        {
            columnId: "description",
            displayValue: "Description",
            renderHeaderCell: () => "Description",
            renderCell: (item: ITag) => {
                return (
                    <Tooltip content={item.description} relationship="label">
                        <div style={{ maxWidth: "200px" }}>
                            <span
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                    width: "100%",
                                }}
                            >
                                {item.description}
                            </span>
                        </div>
                    </Tooltip>
                );
            },
            compare: function (a: ITag, b: ITag): number {
                return a.description.localeCompare(b.description);
            },
            isEditableForSave: true,
            isEditableForEdit: true,
            type: ColumnTypes.SingleLineText,
            required: true
        },
        {
            columnId: "category",
            renderHeaderCell: () => "Category",
            displayValue: "Category",
            renderCell: (tag: ITag) => tag.category?.title || "No Category",
            compare: function (a: ITag, b: ITag): number {
                return (a.category?.title || ConstantValues.EMPTY_STRING).localeCompare(b.category?.title || ConstantValues.EMPTY_STRING);
            },
            isEditableForSave: true,
            type: ColumnTypes.SingleSelection,
            required: true
        },
        {
            columnId: "attachments",
            displayValue: "Attachments",
            renderHeaderCell: () => "Attachments",
            renderCell: (tag: ITag) => {
                if (!tag.attachments || tag.attachments.length === 0) {
                    return "No Attachments";
                }

                return (
                    <div style={{ display: "flex", flexDirection: "column", maxWidth: "200px", }}>
                        {tag.attachments.slice(0, 3).map((file: IFile) => (
                            <Tooltip content={file.filename} relationship="label" key={file.filename}>
                                <TableCellLayout>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                            width: "80%"
                                        }}
                                    >
                                        {file.filename}
                                    </span>
                                </TableCellLayout>
                            </Tooltip>
                        ))}

                        {tag.attachments.length > 3 && (
                            <Tooltip
                                content={tag.attachments.slice(3).map(file => file.filename).join(", ")}
                                relationship="label"
                            >
                                <span>+ {tag.attachments.length - 3} more</span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
            compare: function (a: ITag, b: ITag): number {
                if (a.attachments === undefined || b.attachments === undefined) {
                    return 0;
                }

                return a.attachments.length - b.attachments.length;
            },
            isEditableForEdit: true,
            type: ColumnTypes.Attachments,
        },
        {
            columnId: "createdAt",
            displayValue: "Created At",
            renderHeaderCell: () => "Created At",
            renderCell: (tag: ITag): JSX.Element => {
                if (!tag.createdAt || isNaN(tag.createdAt)) {
                    return <span>-</span>;
                }

                if (!tag.createdAt || isNaN(tag.createdAt)) {
                    return <span>-</span>;
                }

                const date: Date = new Date(tag.createdAt * 1000);
                return <span>{date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
            },
            compare: (a: ITag, b: ITag): number => a.createdAt - b.createdAt,

        },
        {
            columnId: "updatedAt",
            displayValue: "Updated At",
            renderHeaderCell: () => "Updated At",
            renderCell: (tag: ITag): JSX.Element => {
                if (!tag.updatedAt || isNaN(tag.updatedAt)) {
                    return <span>-</span>;
                }

                if (!tag.updatedAt || isNaN(tag.updatedAt)) {
                    return <span>-</span>;
                }

                const date: Date = new Date(tag.updatedAt * 1000);
                return <span>{date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
            },
            compare: (a: ITag, b: ITag): number => a.updatedAt - b.updatedAt,
        },
        {
            columnId: "userId",
            displayValue: "Author",
            renderHeaderCell: () => "Author",
            renderCell: (tag: ITag): JSX.Element => {
                return (
                    <TableCellLayout media={<Avatar aria-label={tag.displayName} name={tag.displayName} />}>
                        {tag.displayName}
                    </TableCellLayout>
                );
            },
            compare: function (a: ITag, b: ITag): number {
                return a.displayName.localeCompare(b.displayName);
            },
        }
    ], [])

    const [categories, setCategories] = useState<ICategory[]>(props.categories);
    const [tags, setTags] = useState<ITag[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(ConstantValues.EMPTY_STRING);
    const [filteredTags, setFilteredTags] = useState<ITag[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
    const [showLoseEditChangesDialog, setShowLoseEditChangesDialog] = useState<boolean>(false);
    const [updatedTag, setUpdatedTag] = useState<ITag | null>(null);
    const [newTag, setNewTag] = useState<ITag>({ id: "new" } as ITag);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [originalTag, setOriginalTag] = useState<ITag | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const itemsPerPage: number = 8;
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
    const itemsStartIndex: number = selectedPageIndex * itemsPerPage;
    const itemsEndIndex: number = (selectedPageIndex + 1) * itemsPerPage;
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [isSortDescending, setIsSortDescending] = useState<boolean>(false);
    const [validationMessages, setValidationMessages] = useState<Record<string, string[]>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const queryClient: QueryClient = useQueryClient()
    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value);
    const attachments: FileWithId[] = useSelector((state: RootState) => state.currentAttachments.value);

    const focusableGroupAttr = useFocusableGroup({ tabBehavior: "limited-trap-focus" });
    const dispatch = useDispatch();

    const pageCount: number = useMemo((): number => {
        return Math.ceil(filteredTags.length / itemsPerPage);
    }, [filteredTags]);

    const paginatedTags: ITag[] = filteredTags.slice(itemsStartIndex, itemsEndIndex);

    const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
        {
            columns,
            items: paginatedTags
        },
        [
            useTableColumnSizing_unstable({
                columnSizingOptions,
                autoFitColumns: false
            })
        ]
    );

    const rows: TableRowData<ITag>[] = getRows();

    useEffect((): void => {
        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        if (savedSortingOptions) {
            const parsedOptions = JSON.parse(savedSortingOptions);
            const listSortingOptions = parsedOptions['Tags'];

            if (listSortingOptions) {
                setSortColumn(listSortingOptions.column);
                setIsSortDescending(listSortingOptions.direction === 'desc');
            }
        }
    }, []);

    useEffect((): void => {
        if (!categories) {
            return;
        }

        const currentTags: ITag[] = getTagsFromCategories(categories);
        setTags(currentTags);
        setFilteredTags(currentTags);
    }, [categories]);

    useEffect((): void => {
        let updatedTags = [...tags];

        if (sortColumn) {
            const column: ITableColumn<ITag> | undefined = columns.find((col: ITableColumn<ITag>): boolean => col.columnId === sortColumn);
            if (column && column.compare) {
                updatedTags.sort((a: ITag, b: ITag): number => column.compare(a, b) * (isSortDescending ? -1 : 1));
            }
        }

        setFilteredTags(updatedTags);
    }, [tags, sortColumn, isSortDescending]);

    const onPageChange = (index: number): void => setSelectedPageIndex(index);

    const onRenderDetailsFooter = (): JSX.Element => (
        <div style={{ position: "sticky", bottom: 0, backgroundColor: tokens.colorNeutralBackground2, padding: "10px", textAlign: "center" }}>
            {pageCount > 1 && (
                <Paginator
                    selectedIndex={selectedPageIndex}
                    pageCount={pageCount}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={tags.length}
                    displayPosition={true}
                    onPageChange={onPageChange}
                />
            )}
        </div>
    );

    const mapCategoriesToDropdownOptions = (categories: ICategory[]): IDropdownOption[] => {
        return categories?.map((category: ICategory): IDropdownOption => ({
            key: category.id,
            text: category.title
        }));
    };

    const getTagsFromCategories = (categories: ICategory[]): ITag[] => {
        return categories.reduce((acc: ITag[], category: ICategory) => {
            if (category.tags && category.tags.length > 0) {
                const tagsWithCategory = category.tags?.map((tag: ITag): ITag => ({
                    ...tag,
                    category,
                }));
                return [...acc, ...tagsWithCategory];
            }
            return acc;
        }, [] as ITag[]);
    };

    const getListViewCell = (
        tag: ITag,
        column: ITableColumn<ITag>,
        isDisabled: boolean,
        onChange: (event: any, value: string | undefined) => void
    ): JSX.Element => {
        const value = tag[column.columnId as keyof ITag];
        const isNewTag = tag.id === "new";
        let isColumnEditable: boolean = false;

        if (isNewTag) {
            isColumnEditable = column.isEditableForSave ?? false;
        } else {
            isColumnEditable = column.isEditableForEdit ?? false;
        }

        const isBeingEdited: boolean = (tag.id === updatedTag?.id && isEditing) || isNewTag;

        if (column.columnId === "icon") {
            if (validationMessages[tag.id]?.length > 0) {
                return (
                    <Tooltip
                        content={
                            <span>
                                {validationMessages[tag.id].map((msg: string, index: number): JSX.Element => (
                                    <React.Fragment key={index}>
                                        {msg}
                                        {index < validationMessages[tag.id].length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </span>
                        }
                        relationship="label"
                    >
                        <ErrorCircle20Regular
                            style={{
                                color: "red",
                                display: "block",
                                margin: "0 auto",
                                width: "20px",
                                height: "20px"
                            }}
                        />
                    </Tooltip>
                );
            } else if (isBeingEdited && hasChanges && ((isEditing && !isNewTag) || (!isEditing && isNewTag))) {
                return (
                    <EditRegular
                        style={{
                            color: NHColors.primary,
                            display: "block",
                            margin: "0 auto",
                            width: "20px",
                            height: "20px"
                        }}
                    />
                );
            }
        }

        if (column.type === ColumnTypes.Attachments && isBeingEdited && ((isEditing && !isNewTag) || (!isEditing && isNewTag))) {
            return <TagWithAttachments
                onAttachmentsChange={handleAttachmentsChange}
                size={"small" as TagSize}
                entity={tag}
                entityType="tag"
                secondaryActionFunction={false}
                isEditable={true}
            />;
        }

        if (!isDisabled && isBeingEdited) {
            return (
                <CellFactory
                    value={value !== undefined ? String(value) : ConstantValues.EMPTY_STRING}
                    editable={isColumnEditable}
                    onChange={onChange}
                    type={column.type ?? ColumnTypes.SingleLineText}
                    options={column.type === ColumnTypes.SingleSelection ? mapCategoriesToDropdownOptions(categories) : undefined}
                />
            );
        }

        return (
            <React.Fragment>
                {column.renderCell ? column.renderCell(tag) : renderCellValue(tag, column.columnId as keyof ITag)}
            </React.Fragment>
        );
    };

    const handleEditClick = (tag: ITag) => {
        if (isEditing && updatedTag && updatedTag.id === tag.id) {
            if (hasChanges) {
                setShowLoseEditChangesDialog(true);
            } else {
                dispatch(clearAttachments());
                setIsEditing(false);
                setUpdatedTag(null);
            }
            return;
        }

        setOriginalTag({ ...tag });
        setIsEditing(true);
        setUpdatedTag(tag);

        if (tag.attachments && tag.attachments.length > 0) {
            const convertedFiles: FileWithId[] = tag.attachments.map((attachment) => ({
                file: new File([], attachment.filename),
                id: attachment.id,
            }));
            dispatch(setAttachments(convertedFiles));
        }

        setHasChanges(false);
    };

    const handleDeleteClick = (tag: ITag) => {
        setShowDeleteDialog(true);
        setUpdatedTag(tag);
    };

    const handleSaveClick = (): void => {
        const categoryToUpdate: ICategory | undefined = categories.find((category: ICategory) => category.id === newTag.category?.id);
        if (categoryToUpdate?.tags.some((tag: ITag): boolean => tag.name.toLowerCase() === newTag.name.toLowerCase())) {
            setErrorMessage(`The tag name "${newTag.name}" already exists in this category.`);
            setTimeout(() => setErrorMessage(null), 5000);
            return;
        }

        const itemToValidate: ITag = updatedTag || newTag;
        const itemId: string = itemToValidate.id || "new";

        const messages: string[] = validateFields(itemToValidate, columns);

        if (messages.length > 0) {
            setValidationMessages((prevMessages) => ({
                ...prevMessages,
                [itemId]: messages,
            }));
            return;
        }

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[itemId];
            return newMessages;
        });

        setErrorMessage(null);
        setShowSaveDialog(true);
    };

    const handleCellChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof ITag, tagId: string) => {
        setHasChanges(true);
        let value: any;
        const column: ITableColumn<ITag> | undefined = columns.find((column: ITableColumn<ITag>): boolean => column.columnId === key);
        if (!column) {
            return;
        }

        if (column.type && column.type === ColumnTypes.SingleSelection) {
            value = event.target.textContent;
            const foundCategory: ICategory | undefined = categories.find((category: ICategory): boolean => category.title === value);
            value = {
                id: foundCategory?.id,
                title: value,
                tags: foundCategory?.tags
            };
        }
        else {
            value = event.target.value;
        }
        setTags((prevTags: Array<ITag>): Array<ITag> => prevTags?.map((tag): ITag => {
            if (tag.id === tagId) {
                return { ...tag, [key]: value };
            }

            return tag;
        }));
        setUpdatedTag((prevTag) => ({ ...prevTag, [key]: value } as ITag));

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages[tagId];
            return newMessages;
        });
    };

    const handleNewTagChange = (event: React.ChangeEvent<HTMLInputElement>, key: keyof ITag): void => {
        let newValue: any = event.target.value;
        const column: ITableColumn<ITag> | undefined = columns.find((column: ITableColumn<ITag>): boolean => column.columnId === key);
        if (!column) {
            return;
        }

        if (column.type && column.type === ColumnTypes.SingleSelection) {
            newValue = event.target.textContent;
            const foundCategory: ICategory | undefined = categories.find((category: ICategory): boolean => category.title === newValue);
            newValue = {
                id: foundCategory?.id,
                title: newValue,
                tags: foundCategory?.tags
            }
        }

        const tagToUpdate: ITag = { ...newTag, [key]: newValue } as ITag;
        const allFieldsEmpty: boolean = Object.entries(tagToUpdate)
            .filter(([key]) => key !== 'id')
            .every(([_, value]) => value === ConstantValues.EMPTY_STRING);

        setValidationMessages((prevMessages) => {
            const newMessages = { ...prevMessages };
            delete newMessages["new"];
            return newMessages;
        });

        if (allFieldsEmpty) {
            setHasChanges(false);
            setNewTag({ id: 'new' } as ITag);
            return;
        }

        setHasChanges(true);
        setNewTag(tagToUpdate);
    };

    const resetStates = (): void => {
        setIsEditing(false);
        setUpdatedTag(null);
        setHasChanges(false);
        setOriginalTag(null);
        setNewTag({ id: 'new' } as ITag);
        setErrorMessage(null);
        setValidationMessages({});
        dispatch(clearAttachments());
    };

    const onDeleteConfirmed = async (): Promise<void> => {
        if (!updatedTag || !updatedTag.id) {
            return;
        }

        setIsLoading(true);
        setShowDeleteDialog(false);
        const categoryToUpdate: ICategory | undefined = categories?.find((category: ICategory): boolean =>
            category.tags.some((tag: ITag): boolean => tag.id === updatedTag.id)
        );
        if (!categoryToUpdate) {
            return;
        }

        const updatedTags: Array<ITag> = categoryToUpdate.tags.filter((tag) => tag.id !== updatedTag.id);
        const updatedCategory: ICategory = { ...categoryToUpdate, tags: updatedTags };

        await updateCategory({ category: updatedCategory });

        await queryClient.invalidateQueries('categories');
        setIsLoading(false);
    };

    const handleAttachmentsChange = (tagId: string, initialFiles: FileWithId[], updatedFiles: FileWithId[], remove?: boolean): void => {
        const originalTag: ITag | undefined = tags.find((tag: ITag) => tag.id === tagId);

        const convertedFiles: IFile[] = updatedFiles.map(convertFileWithIdToIFile);

        if (remove) {
            const filesToRemove: FileWithId[] = initialFiles.filter(
                (initialFile) => !updatedFiles.some((updatedFile) => updatedFile.file.name === initialFile.file.name && updatedFile.id === initialFile.id)
            );

            filesToRemove.forEach((file: FileWithId) => dispatch(removeAttachment(file.file.name)));
        }

        setTags((prevTags: ITag[]): ITag[] =>
            prevTags.map((tag: ITag): ITag => {
                if (tag.id === tagId) {
                    return { ...tag, attachments: convertedFiles };
                }
                return tag;
            })
        );

        if (updatedTag && updatedTag.id === tagId) {
            setUpdatedTag({ ...updatedTag, attachments: convertedFiles });
        } else if (newTag.id === tagId) {
            setNewTag({ ...newTag, attachments: convertedFiles });
        }

        const originalAttachments: IFile[] = originalTag?.attachments || [];
        const attachmentsAreEqual: boolean =
            originalAttachments.length === convertedFiles.length &&
            originalAttachments.every((origFile, index) => origFile.filename === convertedFiles[index].filename && origFile.id === convertedFiles[index].id);

        if (attachmentsAreEqual) {
            setHasChanges(false);
            return;
        }

        setHasChanges(true);
    };

    const onSaveConfirmed = async (): Promise<void> => {
        setShowSaveDialog(false);

        try {
            setIsLoading(true);

            if (updatedTag) {
                await handleUpdateTag();
            } else if (newTag && newTag.name) {
                await handleCreateNewTag();
            }

            setIsLoading(false);

            await queryClient.invalidateQueries('categories');
            resetStates();
        } catch (error) {
            console.error("Error while saving or adding tag:", error);
            setIsLoading(false);
        }
    };

    const handleUpdateTag = async (): Promise<void> => {
        if (!updatedTag) {
            return;
        }

        updatedTag.updatedAt = Math.floor(Date.now() / 1000);

        const categoryToUpdate: ICategory | undefined = findCategoryByTagId(updatedTag.id);
        if (!categoryToUpdate) {
            throw new Error("Category not found.");
        }

        const uploadedFiles: IFile[] = await uploadFilesToStorage(attachments, "tag", updatedTag.id);

        updatedTag.attachments = uploadedFiles;

        const updatedTags = categoryToUpdate.tags.map((tag: ITag): ITag =>
            tag.id === updatedTag.id ? updatedTag! : tag
        );

        const updatedCategory = { ...categoryToUpdate, tags: updatedTags };
        await updateAndSetCategories(updatedCategory);
    };

    const handleCreateNewTag = async (): Promise<void> => {
        newTag.id = ConstantValues.EMPTY_STRING;
        newTag.createdAt = Math.floor(Date.now() / 1000);
        newTag.updatedAt = Math.floor(Date.now() / 1000);

        const categoryToUpdate = findCategoryByTitle(newTag.category?.title);
        if (!categoryToUpdate) throw new Error("Category not found.");

        let updatedTags = [...categoryToUpdate.tags, newTag];
        let updatedCategory = { ...categoryToUpdate, tags: updatedTags };

        updatedCategory = await updateCategory({ category: updatedCategory });
        const newlyCreatedTag = findTagByName(updatedCategory, newTag.name);
        if (!newlyCreatedTag) throw new Error("Tag not found.");

        if (attachments.length > 0) {
            const uploadedFiles = await uploadFilesToStorage(attachments, "tag", newlyCreatedTag.id);
            newlyCreatedTag.attachments = uploadedFiles;
        }

        updateStateWithNewTag(newlyCreatedTag, updatedCategory);
    };

    const findCategoryByTagId = (tagId: string): ICategory | undefined => {
        return categories.find((category) =>
            category.tags.some((tag: ITag): boolean => tag.id === tagId)
        );
    };

    const findCategoryByTitle = (title?: string): ICategory | undefined => {
        return categories.find((category) => category.title === title);
    };

    const findTagByName = (category: ICategory, name: string): ITag | undefined => {
        return category.tags.find((tag: ITag) => tag.name === name);
    };

    const updateAndSetCategories = async (updatedCategory: ICategory): Promise<void> => {
        await updateCategory({ category: updatedCategory });

        setCategories((prevCategories) =>
            prevCategories.map((category) =>
                category.id === updatedCategory.id ? updatedCategory : category
            )
        );

        setTags((prevTags) =>
            prevTags.map((tag) =>
                tag.id === updatedCategory.tags.find((t) => t.id === tag.id)?.id ? tag : tag
            )
        );
    };

    const updateStateWithNewTag = (newlyCreatedTag: ITag, updatedCategory: ICategory): void => {
        setTags((prevTags) => [...prevTags, newlyCreatedTag]);

        setCategories((prevCategories) =>
            prevCategories.map((category) =>
                category.id === updatedCategory.id ? updatedCategory : category
            )
        );
    };

    const onLoseEditChangesConfirmed = (): void => {
        setTags((prevTags: ITag[]) =>
            prevTags?.map((prevTag: ITag): ITag => (prevTag.id === originalTag?.id ? originalTag : prevTag))
        );
        resetStates();
        setShowLoseEditChangesDialog(false);
    };

    const getDeleteDialogContent = (): JSX.Element => (
        <ActionDialog
            isVisible={showDeleteDialog}
            title={"Delete Alert"}
            message={"Are you sure you want to delete this tag?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onDeleteConfirmed}
            onDismiss={(): void => {
                setUpdatedTag(null);
                setShowDeleteDialog(false)
            }}
        />
    );

    const getSaveDialogContent = (): JSX.Element => (
        <ActionDialog
            isVisible={showSaveDialog}
            title={"Save Alert"}
            message={"Are you sure you want to save this tag?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onSaveConfirmed}
            onDismiss={(): void => setShowSaveDialog(false)}
        />
    );

    const getLoseEditChangesDialog = (): JSX.Element => (
        <ActionDialog
            isVisible={showLoseEditChangesDialog}
            title={"Edit Alert"}
            message={"Are you sure you want to lose the edit changes of the tag?"}
            confirmationMessage={"Yes"}
            dismissMessage={"No"}
            onConfirmation={onLoseEditChangesConfirmed}
            onDismiss={(): void => setShowLoseEditChangesDialog(false)}
        />
    );

    if (isLoading) {
        return <InputSkeleton style={{ width: "100%" }} />;
    }

    const isTagDisabledForEdit = (tag: ITag): boolean => {
        return (hasChanges && updatedTag?.id !== tag.id) || (!currentUser.isAdmin && tag.userId !== currentAccount.id.toString());
    };

    const isTagDisabledForDelete = (tag: ITag): boolean => {
        return (hasChanges && updatedTag?.id !== tag.id) || (!currentUser.isAdmin && tag.userId !== currentAccount.id.toString());
    };

    const onSortColumnChanged = (columnKey: string, descending: boolean) => {
        setSortColumn(columnKey);
        setIsSortDescending(descending);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        sortingOptions['Tags'] = {
            column: columnKey,
            direction: descending ? 'desc' : 'asc'
        };

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const onClearColumnChanged = () => {
        setSortColumn(null);
        setIsSortDescending(false);

        const savedSortingOptions = localStorage.getItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY);
        let sortingOptions = savedSortingOptions ? JSON.parse(savedSortingOptions) : {};

        delete sortingOptions['Tags'];

        localStorage.setItem(ConstantValues.SORTING_OPTIONS_CACHE_KEY, JSON.stringify(sortingOptions));
    };

    const getColumnActions = (column: ITableColumn<ITag>): IColumnActionsMenuProps => {
        const isColumnSorted: boolean = sortColumn === column.columnId;
        const isColumnSortable: boolean = column.isSortable === undefined ? true : column.isSortable;

        return {
            columnKey: column.columnId.toString(),
            displayValue: column.displayValue,
            isSortable: isColumnSortable,
            sortingDirection: isColumnSorted ? (isSortDescending ? SortingDirection.Descending : SortingDirection.Ascending) : SortingDirection.None,
            onSortClicked: (columnKey: string, descending: boolean) => onSortColumnChanged(columnKey, descending),
            onClear: onClearColumnChanged
        };
    };

    const getUpdatedColumns = (): ITableColumn<ITag>[] => {
        return columns.map((column: ITableColumn<ITag>): ITableColumn<ITag> => {
            if (column.columnId) {
                column.renderHeaderCell = (): JSX.Element => {
                    const customColumn: ITableColumn<ITag> | undefined = columns.find((c: ITableColumn<ITag>): boolean => c.columnId === column.columnId.toString());
                    if (!customColumn) {
                        return <React.Fragment />;
                    }

                    if (column.columnId === "icon") {
                        return <React.Fragment />;
                    }

                    const actions: IColumnActionsMenuProps = getColumnActions(column);
                    return (
                        <Stack horizontal style={{ alignItems: 'center' }}>
                            <Label>{customColumn.displayValue} </Label>
                            {customColumn.required && (
                                <span style={{ color: 'red', marginLeft: '2px', fontSize: '12px' }}>*</span>
                            )}
                            <ColumnActionsMenu {...actions} />
                        </Stack>
                    );
                };
            }

            return column;
        });
    };

    const handleSearchChange = (event: SearchBoxChangeEvent, data: InputOnChangeData): void => {
        const query = data.value || ConstantValues.EMPTY_STRING;
        setSearchQuery(query);

        if (query === ConstantValues.EMPTY_STRING) {
            setFilteredTags(tags);
        } else {
            setFilteredTags(
                tags.filter(
                    (tag: ITag) => tag.name.toLowerCase().includes(query.toLowerCase())
                )
            );
        }

        setSelectedPageIndex(0);
    };

    return (
        <Stack tokens={setGapBetweenHeadersAndDetailsList} className={containerClassName}>
            {showDeleteDialog && getDeleteDialogContent()}
            {showSaveDialog && getSaveDialogContent()}
            {showLoseEditChangesDialog && getLoseEditChangesDialog()}
            {errorMessage && (
                <MessageBar intent={"warning"}>
                    {errorMessage}
                </MessageBar>
            )}
            <SearchBox
                placeholder="Search by Name"
                value={searchQuery}
                onChange={handleSearchChange}
                style={searchBoxStyle}
            />
            <Toolbar style={{
                marginBottom: '10px',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                padding: '10px 0'
            }}
                aria-label="Actions">
                <ToolbarButton
                    icon={<Add16Regular />}
                    disabled={!hasChanges}
                    onClick={handleSaveClick}
                    aria-label="Save"
                >
                    Save
                </ToolbarButton>
            </Toolbar>

            <StackItem>
                <Table ref={tableRef} {...columnSizing_unstable.getTableProps()} noNativeElements={true}>
                    <TableHeader>
                        <TableRow>
                            {columns?.map((column: ITableColumn<ITag>): JSX.Element => (
                                <TableHeaderCell key={column.columnId} {...columnSizing_unstable.getTableHeaderCellProps(column.columnId)}>
                                    {column.renderHeaderCell()}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {rows?.map((item: TableRowData<ITag>): JSX.Element => {
                            return (
                                <TableRow key={item.item.id}>
                                    {getUpdatedColumns()?.map((column: ITableColumn<ITag>): JSX.Element => (
                                        <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                            {getListViewCell(
                                                item.item,
                                                column,
                                                IsNullUndefinedOrEmpty(isEditing && updatedTag?.id === item.item.id ? column.isEditableForEdit : column.isEditableForSave),
                                                (event, value) => handleCellChange(event, column.columnId as keyof ITag, item.item.id)
                                            )}
                                        </TableCell>
                                    ))}
                                    <TableCell role="gridcell" tabIndex={0} {...focusableGroupAttr}>
                                        <TableCellLayout>
                                            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center', padding: '8px 0' }}>
                                                <Button
                                                    icon={<EditRegular />}
                                                    appearance="outline"
                                                    disabled={isTagDisabledForEdit(item.item)}
                                                    onClick={(): void => handleEditClick(item.item)}
                                                    aria-label="Edit"
                                                    style={{ padding: '6px' }}
                                                />
                                                <Button
                                                    icon={<DeleteRegular />}
                                                    appearance="outline"
                                                    disabled={isTagDisabledForDelete(item.item)}
                                                    onClick={(): void => handleDeleteClick(item.item)}
                                                    aria-label="Delete"
                                                    style={{ padding: '6px' }}
                                                />
                                            </div>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            );
                        })
                        }

                        <TableRow>
                            {columns?.map((column: ITableColumn<ITag>): JSX.Element => (
                                <TableCell key={column.columnId} {...columnSizing_unstable.getTableCellProps(column.columnId)}>
                                    {getListViewCell(
                                        newTag,
                                        column,
                                        isEditing || updatedTag !== null,
                                        (event, value) => handleNewTagChange(event, column.columnId as keyof ITag)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </StackItem>
            <StackItem>{onRenderDetailsFooter()}</StackItem>
        </Stack>
    );
};