import {
    Body2,
    Button,
    Caption2,
    Card, CardHeader,
    Image,
    Input,
    Link,
    Persona,
    Subtitle2,
    Tag,
    Text,
    tokens,
    useId
} from "@fluentui/react-components";
import { Clipboard12Filled, Clipboard12Regular, Edit12Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { TagWithAttachments } from "components/tagWithAttachments/TagWithAttachments";
import { LLMType } from "enums/LLMType";
import { IFile } from "models/IFile.js";
import { IMessage } from "models/IMessage";
import { ITag } from "models/ITag.js";
import { useEffect, useRef, useState } from "react";
import Markdown from 'react-markdown';
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { NHColors } from "utils/generalUtils";
import { v4 as uuidv4 } from "uuid";
import { addMessageToConversation, getAIResponse, getDownloadSignedUrl, updateConversation } from "../../../api.js";
import { resolveAsset } from "../../../common/fileIcons";
import { getChunkAndUpdateAIMessage } from "../../../common/streamProcessing.js";
import { resetAIGeneratedSlice } from "../../../redux/aiGeneratedMessage";
import { addMessageToCurrentMessages, setCurrentMessages } from "../../../redux/messages";
import { setTagsForKey } from "../../../redux/tags";
import { setInitialMessage, setTemporaryChatOpen } from "../../../redux/temporaryChat";
import { AIResponse } from "../AIResponse";
import { SkeletonChat } from "../SkeletonChat";
import { StickyHeader } from "../StickyHeader";
import { useStyles } from "./ConversationMessages.styles";

const updateConversationWithNewMessageAndSetANewResponse = async (
    {
        lastMessageRemaining,
        currentlySelectedConversation,
        editMessageValue,
        setMessageLoadingState,
        currentMessages,
        setEditMessageValue,
        setEditMessageId,
        dispatch,
        currentTags,
        currentLLMConfig,
        setIsMessageLoading
    }: {
        lastMessageRemaining: IMessage,
        currentlySelectedConversation: any,
        editMessageValue: string,
        setMessageLoadingState: any,
        currentMessages: IMessage[],
        setEditMessageValue: (value: string | null) => void,
        setEditMessageId: (id: string | null) => void,
        dispatch: any,
        currentTags: any,
        currentLLMConfig: any,
        setIsMessageLoading: (loading: boolean) => void
    }) => {
    setMessageLoadingState(true)
    const reaminingMessages = []
    for (const message of currentMessages.slice()) {
        if (message?.id === lastMessageRemaining?.id) {
            break
        }

        reaminingMessages.push(message)
    }

    setEditMessageValue(null)
    setEditMessageId(null)
    const conversationCopy = { ...currentlySelectedConversation }

    conversationCopy.messages = reaminingMessages
    const updatedMessageCopy = { ...lastMessageRemaining, content: editMessageValue }
    conversationCopy.messages = [...conversationCopy?.messages, updatedMessageCopy]
    await updateConversation({ conversation: conversationCopy })

    dispatch(setCurrentMessages([...conversationCopy.messages]))

    const response = await getAIResponse({
        llmConfig: currentLLMConfig,
        messages: [...conversationCopy.messages]
    })

    const { content, sourceDocuments } = await getChunkAndUpdateAIMessage(response, response.body, dispatch, null, setMessageLoadingState)
    const newAIMessage = await addMessageToConversation({
        message: {
            content: content,
            files: [],
            isHidden: false,
            role: "assistant",
            tags: currentTags,
            sourceDocuments,

            llmConfig: currentLLMConfig,
        },
        conversationId: conversationCopy?.id
    })

    const remainingMessagesCopy = [...conversationCopy.messages, newAIMessage]
    dispatch(setCurrentMessages(remainingMessagesCopy))
    dispatch(resetAIGeneratedSlice())
}

export const ConversationMessages = ({ isMessageLoading, setMessageLoadingState, scroll }: { isMessageLoading: any, setMessageLoadingState: any, scroll: any }) => {
    const currentSelectedConversation: any = useSelector((state: RootState) => state.currentConversationSetter.value);
    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value)
    const currentMessages: IMessage[] = useSelector((state: RootState) => state.currentMessagesSetter.value);
    const temporaryChatIsOpen: boolean = useSelector((state: RootState) => state.temporaryChatSlice.isOpen);

    const navigate = useNavigate();

    const aiGenerateMessage: any = useSelector((state: RootState) => state.aiGenerateSlice.value)
    const [hoveredMessageId, setHoveredMessageId] = useState<any>(null);
    const [copiedMessageId, setCopiedMessageId] = useState<any>(null);
    const [editMessageId, setEditMessageId] = useState<any>(null);
    const [editMessageValue, setEditMessageValue] = useState<any>(null);
    const [textToRewrite, setTextToRewrite] = useState<any>(null)
    const [textToRewriteBoundingRect, setTextToRewriteBoundingRect] = useState<any>(null);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState<boolean>(true);

    const currentLLMConfig = useSelector((state: RootState) => state.currentLLMConfigSlice.config[ConstantValues.CHAT] || { llmType: LLMType.OPENAI, rag: false });
    const currentTags: ITag[] = useSelector((state: RootState) => state.currentTagsSlice.tags[ConstantValues.CHAT] || [])
    const editMessageInputId = useId("input");

    const messagesEndRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const messagesStartRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const userDisplayName = currentSelectedConversation?.userId === currentUser?.id ? currentUser?.displayName : currentSelectedConversation?.displayName
    const styles = useStyles();
    const dispatch = useDispatch()

    useEffect((): void => {
        if (currentMessages?.length) {
            let latestUserMessage = currentMessages?.length - 1
            if (latestUserMessage > 0)
                latestUserMessage -= 1
            if (currentMessages[latestUserMessage]?.tags && !currentTags?.length)
                dispatch(setTagsForKey({ key: ConstantValues.CHAT, tags: currentMessages[latestUserMessage]?.tags ?? [] }));

        }
    }, [currentMessages])

    useEffect((): void => {
        if (currentMessages.length % 2 === 1 && autoScrollEnabled) {
            scrollToBottom();
        }
    }, [currentMessages, aiGenerateMessage, currentSelectedConversation?.id]);

    useEffect((): void => {
        if (currentMessages.length % 2 !== 0) {
            handleUserMessage();
        }
    }, [currentMessages]);

    useEffect(() => {
        const container: HTMLDivElement | null = messagesContainerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                messagesContainerRef.current &&
                !messagesContainerRef.current.contains(event.target as Node)
            ) {
                setTextToRewriteBoundingRect(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [messagesContainerRef]);

    const handleUserMessage = (): void => {
        setAutoScrollEnabled(true);
        scrollToBottom();
    };

    const handleScroll = (): void => {
        if (!messagesContainerRef.current) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;

        if (scrollHeight - scrollTop <= clientHeight + 50) {
            setAutoScrollEnabled(true);
        } else {
            setAutoScrollEnabled(false);
        }

        setTextToRewriteBoundingRect(null);
    };

    const scrollToBottom = (): void => {
        if (autoScrollEnabled) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    const downloadFile = (fileUrl: string) => async () => {
        const fileWithUrl = await getDownloadSignedUrl({ filename: fileUrl });
        window.open(fileWithUrl?.sas, '_blank');
    };

    const updateMessageMutation = useMutation({
        mutationFn: updateConversationWithNewMessageAndSetANewResponse,
        onError: async (error: any, variables: any, context: any) => {
            let newAIMessage = null
            try {
                newAIMessage = await addMessageToConversation({
                    message: {
                        content: error.toString(),
                        files: [],
                        isHidden: false,
                        role: "assistant",
                        tags: currentTags,
                        sourceDocuments: [],
                        llmConfig: currentLLMConfig,
                    },
                    conversationId: context?.conversation?.id
                })
            } catch (e: any) {
                newAIMessage = {
                    id: uuidv4(),
                    content: 'Something went wrong, please try again later. If this problem persists please contact support',
                    files: [],
                    isHidden: false,
                    role: "assistant",
                    tags: currentTags,
                    sourceDocuments: [],
                    llmConfig: currentLLMConfig,
                    createdAt: Date.now()
                }
            }
            finally {
                dispatch(addMessageToCurrentMessages(newAIMessage))
                dispatch(resetAIGeneratedSlice())
                setMessageLoadingState(false)
            }
        }
    })

    const copyToClipboard = (message: IMessage) => (): void => {
        navigator.clipboard.writeText(message.content);
        setCopiedMessageId(message.id);
    };

    const setEditMessage = (message: IMessage) => (): void => {
        setEditMessageValue(message.content);
        setEditMessageId(message.id);
    };

    const desetEditMessage = () => (): void => {
        setEditMessageValue(null);
        setEditMessageId(null);
    };

    const updateValueOfEditingMessage = (ev: any, data: { value: string }): void => {
        setEditMessageValue(data.value)
    };

    const snapSelectionToWord = (): void => {
        const selection: Selection | null = window.getSelection();

        if (selection && !selection.isCollapsed) {
            const range: Range = document.createRange();

            if (selection.anchorNode && selection.focusNode) {
                range.setStart(selection.anchorNode, selection.anchorOffset);
                range.setEnd(selection.focusNode, selection.focusOffset);
            }

            const backwards = range.collapsed;
            range.detach();

            const endNode: Node | null = selection.focusNode;
            const endOffset = selection.focusOffset;

            selection.collapse(selection.anchorNode, selection.anchorOffset);

            const directions = backwards ? ['backward', 'forward'] : ['forward', 'backward'];

            selection.modify("move", directions[0], "character");
            selection.modify("move", directions[1], "word");

            if (endNode) {
                selection.extend(endNode, endOffset);
            }

            selection.modify("extend", directions[1], "character");
            selection.modify("extend", directions[0], "word");
        }
    };

    const getSelectedTextValue = (message: IMessage) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (temporaryChatIsOpen || currentSelectedConversation?.userId !== currentUser?.id) {
            return;
        }

        if (message.role !== "assistant") {
            return;
        }


        snapSelectionToWord();

        const selection: Selection | null = window.getSelection();
        if (!selection || selection.rangeCount === 0) {
            return;
        }

        const selectedBoundingRect: DOMRect = selection.getRangeAt(0).getBoundingClientRect();
        const relative: DOMRect = (document.body.parentNode as Element).getBoundingClientRect();
        const start: number | null = message?.content?.indexOf(selection.toString() || ConstantValues.EMPTY_STRING) || null;
        const end: number | null = start !== null ? start + (selection.toString()?.length || 0) : null;

        setTextToRewrite({ text: selection.toString() || ConstantValues.EMPTY_STRING, start, end });

        if (selection?.toString()?.length > 0) {
            setTextToRewriteBoundingRect({
                left: relative?.left + 220,
                top: messagesStartRef?.current?.clientHeight
                    ? messagesStartRef.current.clientHeight - scroll - 690 + selectedBoundingRect?.top
                    : selectedBoundingRect?.top - 27,
                right: selectedBoundingRect?.right,
                bottom: selectedBoundingRect?.bottom
            });

            setTextToRewriteBoundingRect({
                left: selectedBoundingRect?.left,
                top: selectedBoundingRect?.top - 27,
                right: selectedBoundingRect?.right,
                bottom: selectedBoundingRect?.bottom
            });

            return;
        }

        setTextToRewriteBoundingRect(null);
    };

    const openTemporaryChatForRewrite = (messageToRewrite: IMessage) => (): void => {
        let content: string = ConstantValues.EMPTY_STRING;
        let markdownDelimiter: string = '*';

        for (let i: number = 0; i < messageToRewrite.content.length; i++) {
            if (i === textToRewrite?.start) {
                content += markdownDelimiter
            }
            content += messageToRewrite.content[i]
            if (i === textToRewrite?.end - 1) {
                content += markdownDelimiter
            }
        }

        const remainingMessages: IMessage[] = [];
        for (const message of currentMessages.slice()) {
            if (message?.id === messageToRewrite?.id) {
                remainingMessages.push({ ...message, content });
            }
            else {
                remainingMessages.push(message)
            }
        }

        dispatch(setCurrentMessages(remainingMessages));
        dispatch(setTemporaryChatOpen(true))
        dispatch(setInitialMessage({
            id: messageToRewrite.id,
            content: textToRewrite.text,
            role: messageToRewrite.role,
            start: textToRewrite.start,
            end: textToRewrite.end,
            contentToUpdate: content,
            initialContent: messageToRewrite?.content,
            resetOnClose: true,
            markdownDelimiter: markdownDelimiter
        }))
        setTextToRewrite(ConstantValues.EMPTY_STRING);
        setTextToRewriteBoundingRect(null);
    };

    const containsAIMessage: boolean = currentMessages?.filter((message: IMessage) => message.role === "assistant")?.length > 0

    return <div className={styles.container}>
        <div ref={messagesContainerRef} className={styles.messageWrapper}>
            <StickyHeader sourceKey={ConstantValues.CHAT} />
            <div className={styles.messageContainer}>
                {!currentSelectedConversation?.id
                    ? <div className={styles.noMessagesContainer}>
                        <Image width={150} height={150} src='/images/NH_Logo_Circle.png' />
                        <Subtitle2>How can I help you today ?</Subtitle2>
                    </div>
                    : <></>}
                {currentSelectedConversation?.id && !currentMessages?.length ? [1, 2, 3]?.map((i: number): JSX.Element =>
                    <SkeletonChat key={i} avatarOnRight={i % 2 === 1} />) : <></>}

                {currentMessages.map(
                    (message: IMessage, index: number) =>
                        message.id !== editMessageId
                            ? <div
                                key={index}
                                onMouseEnter={(): void => setHoveredMessageId(message?.id)}
                                onMouseLeave={(): void => setHoveredMessageId(null)}
                                className={`${styles.messageDetails} ${message?.role === "assistant" ? styles.assistantMessage : styles.userMessage}`}
                            >
                                {textToRewriteBoundingRect && message?.role === "assistant"
                                    ? <Button shape="circular" appearance="primary"
                                        onClick={openTemporaryChatForRewrite(message)}
                                        style={{
                                            backgroundColor: NHColors.primary,
                                            position: "fixed",
                                            zIndex: 100,
                                            height: "25px",
                                            width: "50px",
                                            left: textToRewriteBoundingRect.left,
                                            top: textToRewriteBoundingRect.top,
                                            right: textToRewriteBoundingRect.right,
                                            bottom: textToRewriteBoundingRect.bottom
                                        }}>rewrite</Button>
                                    : <></>
                                }
                                <Persona
                                    name={message?.role === "assistant" ? "NorthHighland AI" : userDisplayName}
                                    secondaryText={message?.role}
                                    presence={{ status: message?.role === "assistant" ? "available" : 'offline' }}
                                    size="large"
                                    avatar={
                                        message?.role === "assistant"
                                            ? { image: { src: "/images/NH_Logo_Circle.png" } }
                                            : { name: userDisplayName }
                                    }
                                />
                                <Body2
                                    className={styles.messageContent}
                                    onMouseUp={getSelectedTextValue(message)}
                                >
                                    <Markdown>
                                        {message?.content}
                                    </Markdown>
                                </Body2>
                                <div className={styles.tagWrapper}>
                                    {message?.tags?.map((tag, index) => (
                                        <TagWithAttachments
                                            key={index}
                                            size="small"
                                            entity={tag}
                                            entityType="tag"
                                            // secondaryActionFunction={currentSelectedConversation?.userId === currentUser?.id}
                                            secondaryActionFunction={true}
                                            isEditable={false}
                                            // takeFilesFromEntity={currentSelectedConversation?.userId === currentUser?.id}
                                            takeFilesFromEntity={true}
                                            showTagName={true}
                                        />
                                    ))}
                                    <Tag size="small" appearance="outline">
                                        {message?.llmConfig?.rag ? 'NHQA' : 'Normal Generation'}
                                    </Tag>
                                    {message?.role === "assistant" && (
                                        <Tag size="small">{message?.llmConfig?.llmType}</Tag>
                                    )}
                                </div>
                                <div className={styles.fileCard}>
                                    {message?.files?.map((file: IFile, index) => (
                                        <Card key={index} className={styles.fileCard} size="small">
                                            <CardHeader
                                                style={{ maxWidth: "340px" }}
                                                image={{
                                                    as: "img",
                                                    src: resolveAsset(file?.filename),
                                                    alt: "Word app logo",
                                                    width: "40px",
                                                    height: "40px"
                                                }}
                                                header={
                                                    <Link
                                                        onClick={downloadFile(file.filename ?? ConstantValues.EMPTY_STRING)}
                                                        style={{ cursor: "pointer" }}>
                                                        {decodeURI(file?.filename?.split('/')?.at(-1) || ConstantValues.EMPTY_STRING)}
                                                    </Link>
                                                } />
                                        </Card>
                                    ))}
                                </div>
                                {message?.sourceDocuments ? message?.sourceDocuments?.map((document: any, index: number): JSX.Element =>
                                    <Card key={index} style={{ width: "100%" }}>
                                        <CardHeader
                                            image={{
                                                as: "img", src: resolveAsset(document?.filename, document), alt: "Word app logo",
                                                width: "40px",
                                                height: "40px"
                                            }}
                                            header={<Link onClick={downloadFile(document.filename ?? "")}
                                                style={{ cursor: "pointer" }}>{document?.filename}</Link>
                                            }
                                        />
                                        <p style={{ textAlign: "start", color: tokens?.colorNeutralForeground2 }}>
                                            {document?.content}
                                        </p>
                                    </Card>

                                ) : <div />}
                                <Text italic size={100} className={styles.dateStyle}>
                                    {new Date(message?.createdAt * 1000).toDateString()}
                                </Text>
                                {hoveredMessageId === message?.id
                                    ? <div className={styles.actionsWrapper}>
                                        {copiedMessageId === message?.id
                                            ? <Button size="small" icon={<Clipboard12Filled />} onClick={copyToClipboard(message)}>Copied!</Button>
                                            : <Button size="small" icon={<Clipboard12Regular />} onClick={copyToClipboard(message)}>Copy</Button>
                                        }
                                        {message?.role !== "assistant" ? <Button onClick={setEditMessage(message)} size="small" icon={<Edit12Regular />}>Edit</Button> : <></>}
                                    </div>
                                    : <div style={{ height: "24px" }} />}
                            </div>
                            : <div
                                onMouseEnter={(): void => setHoveredMessageId(message?.id)}
                                onMouseLeave={(): void => setHoveredMessageId(null)}
                                className={`${styles.messageDetails} ${message?.role === "assistant" ? styles.assistantMessage : styles.userMessage}`}
                            >
                                <Persona
                                    name={message?.role === "assistant" ? "NorthHighland AI" : userDisplayName}
                                    secondaryText={message?.role}
                                    presence={{ status: message?.role === "assistant" ? "available" : 'offline' }}
                                    size="large"
                                    avatar={
                                        message?.role === "assistant"
                                            ? { image: { src: "/images/NH_Logo_Circle.png" } }
                                            : { name: userDisplayName }
                                    }
                                />
                                <Input
                                    value={editMessageValue ?? ConstantValues.EMPTY_STRING}
                                    id={editMessageInputId}
                                    onChange={updateValueOfEditingMessage}
                                    className={styles.editInput}
                                />

                                <div className={styles.actionsWrapper}>
                                    <Button appearance="subtle" onClick={desetEditMessage()} size="small">Cancel</Button>
                                    <Button appearance="primary" onClick={
                                        () => updateMessageMutation.mutate({
                                            lastMessageRemaining: message,
                                            currentlySelectedConversation: currentSelectedConversation,
                                            editMessageValue,
                                            setMessageLoadingState,
                                            currentMessages,
                                            setEditMessageValue,
                                            setEditMessageId,
                                            dispatch,
                                            currentTags,
                                            currentLLMConfig,
                                            setIsMessageLoading: setMessageLoadingState
                                        })
                                    } size="small">Save</Button>
                                </div>
                            </div>
                )}
                <AIResponse isLoading={isMessageLoading} aiGenerateMessage={aiGenerateMessage} />
                <div ref={messagesEndRef} />
                {containsAIMessage &&
                    <Caption2 className={styles.captionWrapper}>
                        This is an AI, results may be innacurate. Please double-check responses and use our{" "}
                        <Link onClick={() => navigate("/guidelines")}> <Caption2>guidelines</Caption2>
                        </Link>
                    </Caption2>
                }
            </div>
        </div>
    </div>
}