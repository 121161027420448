import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle } from "@fluentui/react-components";
import { useStyles } from "./ActionDialog.styles";
import IDialogProperties from "./ActionDialog.types";

export const ActionDialog = (props: IDialogProperties): JSX.Element => {
    const styles = useStyles();

    return (
        <Dialog open={props.isVisible} onOpenChange={props.onDismiss}>
            <DialogSurface>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogBody>
                    <p>{props.message}</p>
                </DialogBody>
                <DialogActions>
                    {props.onConfirmation && (
                        <Button appearance="primary" onClick={props.onConfirmation} className={styles.button}>
                            {props.confirmationMessage}
                        </Button>
                    )}
                    {props.onDismiss && (
                        <Button appearance="secondary" onClick={props.onDismiss} >
                            {props.dismissMessage}
                        </Button>
                    )}
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};