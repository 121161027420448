import { tokens } from "@fluentui/react-components";
import { CategoryTagTree } from "components/categoryTagTree/CategoryTagTree";
import { TagWithAttachments } from "components/tagWithAttachments/TagWithAttachments";
import { ITag } from "models/ITag";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { IsNullUndefinedOrEmpty } from "utils/generalUtils";

interface IStickyHeaderProps extends React.PropsWithChildren<{}> {
    sourceKey: string;
};

export const StickyHeader = (props: IStickyHeaderProps) => {
    const currentTags: ITag[] = useSelector((state: RootState) => state.currentTagsSlice.tags[props.sourceKey] || []);
    // const currentSelectedConversation: any = useSelector((state: RootState) => state.currentConversationSetter.value)
    // const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value)

    const stickyHeaderStyle: React.CSSProperties = {
        position: "sticky",
        minHeight: '100px',
        top: "0",
        width: "100%",
        gap: "5px",
        padding: "10px 20px",
        maxHeight: "auto",
        boxSizing: "border-box",
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        backgroundColor: tokens.colorNeutralBackground3,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        zIndex: 10
    };

    const tagContainerStyle: React.CSSProperties = {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
        gap: "10px",
        maxWidth: "66%",
        overflow: "hidden",
        alignItems: "center",
        height: "auto"
    };

    const childrenAndCategoryContainerStyle: React.CSSProperties = {
        display: "flex",
        width: '33%',
        flexDirection: "column",
        gap: IsNullUndefinedOrEmpty(props.children) ? '0px' : '10px',
        justifyContent: "flex-end",
        position: "relative"
    };

    const categoryTagTreeWrapperStyle: React.CSSProperties = {
        position: "absolute",
        top: "110%",
        width: "100%",
        zIndex: 20,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        backgroundColor: "white"
    };

    const tagStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "150px",
        height: "32px",
        borderRadius: "4px",
        boxSizing: "border-box"
    };

    const categoryTagTreeStyle: React.CSSProperties = {
        maxHeight: "200px",
        overflowY: "auto",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "4px",
    };

    return (<>
        <div style={stickyHeaderStyle}>
            <div style={tagContainerStyle}>
                {currentTags?.map((tag: ITag, index: number) => (
                    <div key={index} style={tagStyle}>
                        <TagWithAttachments
                            sourceKey={props.sourceKey}
                            entity={tag}
                            entityType="tag"
                            // secondaryActionFunction={currentSelectedConversation?.userId === currentUser?.id}
                            secondaryActionFunction={true}
                            isEditable={false}
                            // takeFilesFromEntity={currentSelectedConversation?.userId === currentUser?.id}
                            takeFilesFromEntity={true}
                            showTagName={true}
                        />
                    </div>
                ))}
            </div>
            <div style={childrenAndCategoryContainerStyle}>
                {props.children}
                <div style={categoryTagTreeWrapperStyle}>
                    <div style={categoryTagTreeStyle}>
                        <CategoryTagTree dictKey={props.sourceKey} />
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};