import { Modal } from "@fluentui/react";
import { Button, Card, CardHeader, CompoundButton, Field, makeStyles, Text, Textarea } from "@fluentui/react-components";
import { Chat12Regular, ClipboardPaste16Regular, Delete16Regular, DocumentAdd20Regular, Send16Regular } from "@fluentui/react-icons";
import { ConstantValues } from "common/constants";
import { AIModelSettings } from "components/aiModelSettings/AIModelSettings";
import { LLMType } from "enums/LLMType";
import { FileWithId } from "models/FileWithId";
import { IFile } from "models/IFile";
import { IMessage } from "models/IMessage";
import { ITag } from "models/ITag";
import { Fragment, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { uploadFilesToStorage } from "utils/blobUtils";
import { currentAccount, NHColors } from "utils/generalUtils";
import { v4 as uuidv4 } from "uuid";
import { addMessageToConversation, createConversation, getAIResponse, getAITitle, getPrompts, updateConversationTitle } from "../../api";
import { isImage, resolveAsset } from "../../common/fileIcons";
import { getChunkAndUpdateAIMessage } from "../../common/streamProcessing.js";
import { resetAIGeneratedSlice } from "../../redux/aiGeneratedMessage";
import { setCurrentConversation } from "../../redux/conversations";
import { setCurrentFiles } from "../../redux/files";
import { setNewConfigForKey } from "../../redux/llmConfig";
import { addMessageToCurrentMessages } from "../../redux/messages";
import { setCurrentPrompt } from "../../redux/selectedPrompt";
import { setTagsForKey } from "../../redux/tags";
import { setTemporaryChatOpen } from "../../redux/temporaryChat";
import { AddPrompt } from "./addPrompt/AddPrompt";

const useStyles = makeStyles({
    dragOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        fontSize: "24px",
        pointerEvents: "none",
    },
    card: {
        width: "280px",
        height: "fit-content",
    },
    modalImage: {
        maxWidth: '100%',
        maxHeight: '80vh',
        display: 'block',
        margin: '0 auto'
    }
});

const addMessageToChat = async ({
    text,
    currentMessages,
    currentlySelectedConversation,
    uploadFiles,
    currentTags,
    currentLLMConfig,
    setIsMessageLoading,
    setText,
    setUploadFiles,
    dispatch,
    queryClient
}: {
    text: any,
    currentMessages: IMessage[],
    currentlySelectedConversation: any,
    uploadFiles: FileWithId[],
    currentTags: ITag[],
    currentLLMConfig: any,
    setIsMessageLoading: any,
    setText: any,
    setUploadFiles: any,
    dispatch: any,
    queryClient: any
}) => {
    if (!text || !text.replace(/^\s+|\s+$/g, ConstantValues.EMPTY_STRING)) {
        setText(ConstantValues.EMPTY_STRING)
        return;
    }

    setIsMessageLoading(true)

    let conversation = { ...currentlySelectedConversation }
    if (!currentlySelectedConversation?.id) {
        conversation = await createConversation({
            title: "New Conversation",
            pinned: false,
            messages: []
        })
        dispatch(setCurrentConversation(conversation))
    }

    const responseNames: IFile[] = await uploadFilesToStorage(uploadFiles, "user", currentAccount.id)
    const newMessage = await addMessageToConversation({
        message: {
            content: text,
            files: responseNames,
            isHidden: false,
            role: "user",
            tags: currentTags,
            llmConfig: currentLLMConfig,
        },
        conversationId: conversation?.id
    })

    setText(ConstantValues.EMPTY_STRING)
    setUploadFiles([])
    dispatch(setCurrentFiles([]))
    dispatch(addMessageToCurrentMessages(newMessage))

    const response = await getAIResponse({
        llmConfig: currentLLMConfig,
        messages: [...currentMessages, newMessage]
    })

    const { content, sourceDocuments } = await getChunkAndUpdateAIMessage(response, response.body, dispatch, null, setIsMessageLoading)
    const newAIMessage = await addMessageToConversation({
        message: {
            content: content,
            files: responseNames,
            isHidden: false,
            role: "assistant",
            tags: currentTags,
            sourceDocuments: sourceDocuments,
            llmConfig: currentLLMConfig,
        },
        conversationId: conversation?.id
    })
    dispatch(addMessageToCurrentMessages(newAIMessage))
    dispatch(resetAIGeneratedSlice())

    if (currentMessages?.length === 0) {
        const generatedTitle = await getAITitle({ llmConfig: currentLLMConfig, messages: [...currentMessages, newMessage, newAIMessage] })
        if (!generatedTitle?.title) {
            return
        }

        const sanitizedTitle: string = generatedTitle.title.replace(/^"|"$/g, ConstantValues.EMPTY_STRING).trim();
        if (sanitizedTitle === ConstantValues.EMPTY_STRING) {
            return;
        }

        const updatedConversation = await updateConversationTitle({ conversation, title: sanitizedTitle })
        queryClient.invalidateQueries({ queryKey: ['conversations'] })
        dispatch(setCurrentConversation(updatedConversation))
    }
};

export const MessageBar = ({ setIsMessageLoading }: { setIsMessageLoading: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element => {
    const isTemporaryChatOpen = useSelector((state: any) => state.temporaryChatSlice.isOpen)
    const fileInputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const queryClient = useQueryClient();
    const { data: prompts } = useQuery('prompts', async () => {
        const prompts = await getPrompts()
        return prompts
    }, { refetchOnWindowFocus: false });

    const styles = useStyles();
    const dispatch = useDispatch();

    const [text, setText] = useState<string | null>(null);
    const [uploadFiles, setUploadFiles] = useState<FileWithId[]>([]);
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const selectFileFromOS = (): void => fileInputRef.current?.click();

    const currentUser: any = useSelector((state: RootState) => state.currentUserSlice.value);
    const currentlySelectedConversation: any = useSelector((state: RootState) => state.currentConversationSetter.value);
    const currentMessages: IMessage[] = useSelector((state: RootState) => state.currentMessagesSetter.value);
    const currentTags: ITag[] = useSelector((state: RootState) => state.currentTagsSlice.tags[ConstantValues.CHAT] || []);
    const currentLLMConfig = useSelector((state: RootState) => state.currentLLMConfigSlice.config[ConstantValues.CHAT] ?? { llmType: LLMType.OPENAI, rag: false });

    const currentPrompt: any = useSelector((state: RootState) => state.currentPromptSlice.prompts[ConstantValues.CHAT]);

    const chatMutation = useMutation({
        mutationFn: addMessageToChat,
        onError: async (error: any, variables: any, context: any) => {
            let newAIMessage = null
            try {
                newAIMessage = await addMessageToConversation({
                    message: {
                        content: error.toString(),
                        files: variables?.responseNames,
                        isHidden: false,
                        role: "assistant",
                        tags: currentTags,
                        sourceDocuments: [],
                        llmConfig: currentLLMConfig,
                    },
                    conversationId: context?.conversation?.id
                })
            } catch (e: any) {
                newAIMessage = {
                    id: uuidv4(),
                    content: 'Something went wrong, please try again later. If this problem persists please contact support',
                    files: variables?.responseNames,
                    isHidden: false,
                    role: "assistant",
                    tags: currentTags,
                    sourceDocuments: [],
                    llmConfig: currentLLMConfig,
                    createdAt: Date.now()
                }
            }
            finally {
                dispatch(addMessageToCurrentMessages(newAIMessage))
                dispatch(resetAIGeneratedSlice(newAIMessage))
                setIsMessageLoading(false)
            }
        }
    });

    const chatWithAi = () => {
        chatMutation.mutate({
            text,
            currentMessages,
            currentlySelectedConversation,
            uploadFiles,
            currentTags,
            currentLLMConfig,
            setIsMessageLoading,
            setText,
            setUploadFiles,
            dispatch,
            queryClient
        })
    };

    useEffect((): void => {
        setText(ConstantValues.EMPTY_STRING);
        dispatch(setCurrentFiles([]))
        setUploadFiles([])
    }, [currentlySelectedConversation?.id]);

    useEffect((): void => {
        if (currentPrompt) {
            setText(currentPrompt?.description)
        }
    }, [currentPrompt?.id]);

    useEffect(() => {
        const textArea = document.getElementById('messageTextArea');
        const pasteHandler = (event: ClipboardEvent) => handlePaste(event, setUploadFiles, dispatch);
        if (textArea) {
            textArea.addEventListener('paste', pasteHandler);
        }
        return () => {
            if (textArea) {
                textArea.removeEventListener('paste', pasteHandler);
            }
        };
    }, []);


    useEffect(() => {
        const textarea = document.getElementById('messageTextArea');
        if (textarea) {
            textarea.addEventListener('dragenter', handleDragEnter);
            textarea.addEventListener('dragover', handleDragOver);
            textarea.addEventListener('dragleave', handleDragLeave);
            textarea.addEventListener('drop', handleDrop);
        }
        return () => {
            if (textarea) {
                textarea.removeEventListener('dragenter', handleDragEnter);
                textarea.removeEventListener('dragover', handleDragOver);
                textarea.removeEventListener('dragleave', handleDragLeave);
                textarea.removeEventListener('drop', handleDrop);
            }
        };
    }, []);

    const handlePaste = (event: ClipboardEvent, setUploadFiles: React.Dispatch<React.SetStateAction<FileWithId[]>>, dispatch: any) => {
        const clipboardData = event.clipboardData;
        const files: File[] = clipboardData?.files ? Array.from(clipboardData.files) : [];

        if (files.length) {
            const fileWithIds = files.map((file) => {
                let fileName = file.name;
                let fileExtension = fileName.substring(fileName.lastIndexOf('.'));
                let baseName = fileName.substring(0, fileName.lastIndexOf('.'));
                let counter = 1;

                setUploadFiles((prevFiles) => {
                    const existingFileNames = prevFiles.map(existingFile => existingFile.file.name);
                    while (existingFileNames.includes(fileName)) {
                        counter++;
                        fileName = `${baseName} (${counter})${fileExtension}`;
                    }
                    return prevFiles;
                });

                return { file: new File([file], fileName), id: uuidv4() };
            });

            setUploadFiles((prevFiles) => [...prevFiles, ...fileWithIds]);
            dispatch(setCurrentFiles(fileWithIds.map((fileWithId) => fileWithId.file.name)));
        }
    };

    const handleDragEnter = (event: DragEvent) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = (event: DragEvent) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };

    const handleDrop = (event: DragEvent) => {
        event.preventDefault();
        setIsDraggingOver(false);

        const files = Array.from(event.dataTransfer?.files || []);
        const fileWithIds = files.map((file) => ({
            file,
            id: uuidv4()
        }));

        setUploadFiles((prevFiles) => {
            const existingFileNames = prevFiles.map(existingFile => existingFile.file.name);
            const newFiles = fileWithIds.filter(fileWithId => !existingFileNames.includes(fileWithId.file.name));
            dispatch(setCurrentFiles(newFiles.map(file => file.file.name)));

            return [...prevFiles, ...newFiles];
        });
    };

    const openImagePreview = (fileUrl: string) => () => {
        setPreviewImage(fileUrl);
    };

    const closeImagePreview = () => {
        setPreviewImage(null);
    };

    const handleClick = (event: any) => {
        const { target = {} } = event || {};
        target.value = ConstantValues.EMPTY_STRING;
    };

    const importCurrentConversationToUser = async () => {
        const inputMessages = [];

        for (let message of currentMessages) {
            inputMessages.push({
                content: message?.content,
                role: message?.role,
                tags: message?.tags,
                files: message?.files,
                sourceDocuments: message?.sourceDocuments,
                llmConfig: message?.llmConfig,
            })
        }

        const conversation = await createConversation({
            messages: inputMessages,
            title: currentlySelectedConversation?.title,
            pinned: false
        })
        dispatch(setCurrentConversation(conversation))
    };

    const importConversationMutation = useMutation({
        mutationFn: importCurrentConversationToUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['conversations'] })
        }
    });

    const handleFileInputChange = (event: any) => {
        const files: File[] = event.target.files;
        if (!files) return;

        const newFiles: FileWithId[] = Array.from(files).map((file: File): FileWithId => ({ file, id: uuidv4() }));

        setUploadFiles((prevFiles) => {
            const existingFileNames = prevFiles.map(file => file.file.name);
            const filteredFiles = newFiles.filter(file => !existingFileNames.includes(file.file.name));
            return [...prevFiles, ...filteredFiles];
        });

        dispatch(setCurrentFiles(newFiles.map(file => file.file.name)));
    };


    const onEnterPress = async (e: any) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            chatWithAi();
        }
    };

    const updateTextAndResetSelectedPromptIfTextIsEmpty = (e: any, data: any) => {
        if (currentPrompt?.id) {
            dispatch(setCurrentPrompt({ key: ConstantValues.CHAT, prompt: null }));
        }

        setText(data.value);
    };

    const deselectFile = (fileWithId: FileWithId) => () => {
        const remainingFiles: FileWithId[] = uploadFiles.filter((f: FileWithId): boolean => f.file.name !== fileWithId.file.name);
        setUploadFiles(remainingFiles);
        dispatch(setCurrentFiles(remainingFiles.map((f: FileWithId) => f.file.name)));
    };

    const handleTemporaryChatClick = (): void => {
        dispatch(setTemporaryChatOpen(!isTemporaryChatOpen));
        dispatch(setTagsForKey({ key: ConstantValues.TEMPORARY_CHAT, tags: [] }))
        dispatch(setCurrentPrompt({ key: ConstantValues.TEMPORARY_CHAT, prompt: null }));
        dispatch(setNewConfigForKey({ key: ConstantValues.TEMPORARY_CHAT, config: { llmType: LLMType.OPENAI, rag: false } }));
    };

    return (
        <>
            {isDraggingOver && (
                <div className={styles.dragOverlay}>
                    Drag and drop files here
                </div>
            )}

            {previewImage && (
                <Modal isOpen={!!previewImage} onDismiss={closeImagePreview}>
                    <img src={previewImage} alt="Preview" className={styles.modalImage} />
                </Modal>
            )}

            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                {uploadFiles.length > 0 &&
                    uploadFiles.map((fileWithId: FileWithId): JSX.Element => {
                        const isImageFile: boolean = isImage(fileWithId.file.name);
                        return (
                            <Card className={styles.card} size="small" role="listitem" key={fileWithId.file.name}>
                                <CardHeader
                                    image={{
                                        as: "img",
                                        src: resolveAsset(fileWithId.file.name, fileWithId.file),
                                        alt: fileWithId.file.name,
                                        width: "40px",
                                        height: "40px",
                                        onClick: isImageFile && fileWithId
                                            ? openImagePreview(URL.createObjectURL(fileWithId.file))
                                            : undefined,
                                        style: isImageFile
                                            ? { cursor: 'pointer', userSelect: 'none' }
                                            : undefined

                                    }}
                                    header={<Text
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: "150px",
                                            display: "inline-block"
                                        }}
                                        size={100}
                                        weight="semibold"
                                        title={fileWithId.file.name}
                                    >
                                        {fileWithId.file.name}
                                    </Text>}
                                    action={
                                        <Button
                                            appearance="transparent"
                                            icon={<Delete16Regular />}
                                            onClick={deselectFile(fileWithId)}
                                        />
                                    }
                                />
                            </Card>
                        );
                    })
                }

                <div style={{ display: "flex", justifyContent: "start", gap: "5px", flexWrap: 'wrap' }}>
                    {currentlySelectedConversation == null || currentlySelectedConversation?.userId === currentUser?.id
                        ? <AIModelSettings dictKey={ConstantValues.CHAT} />
                        : <Fragment />
                    }
                    {currentlySelectedConversation?.shared && currentlySelectedConversation?.userId !== currentUser?.id
                        ? <div style={{ display: "flex" }}>
                            <Button icon={<ClipboardPaste16Regular />} onClick={() => importConversationMutation.mutate()}>Copy Conversation</Button>
                        </div>
                        : <Fragment />
                    }

                    {currentlySelectedConversation == null || currentlySelectedConversation?.userId === currentUser?.id
                        ? <AddPrompt
                            dictKey={ConstantValues.CHAT}
                            canManagePrompt={true}
                            data={prompts}
                            description={text ?? ConstantValues.EMPTY_STRING}
                            setDescription={setText} />
                        : <Fragment />
                    }

                    {currentlySelectedConversation == null || currentlySelectedConversation?.userId === currentUser?.id
                        ? <div>
                            <Button onClick={handleTemporaryChatClick} icon={<Chat12Regular />}>Temporary Chat</Button>
                        </div>
                        : <Fragment />
                    }

                </div>
                {currentlySelectedConversation == null || currentlySelectedConversation?.userId === currentUser?.id
                    ? <Field style={{ display: "flex" }}>
                        <input onChange={handleFileInputChange} onClick={handleClick} multiple={true} ref={fileInputRef} type='file' hidden />
                        <CompoundButton
                            icon={<DocumentAdd20Regular />}
                            size="small"
                            onClick={selectFileFromOS}
                            style={{ borderBottomRightRadius: "0px", borderTopRightRadius: "0px", borderRight: "0", }}
                        />
                        <Textarea
                            id="messageTextArea"
                            placeholder="How can I help you today?"
                            resize="vertical" style={{ width: "100%", borderRadius: "0px", borderBottomRightRadius: "0px", borderLeft: "0", textAlign: "center" }}
                            value={text ?? ConstantValues.EMPTY_STRING}
                            onChange={updateTextAndResetSelectedPromptIfTextIsEmpty}
                            onKeyDown={onEnterPress} />
                        <CompoundButton
                            icon={<Send16Regular />}
                            appearance="primary"
                            size="small"
                            onClick={chatWithAi}
                            style={{ borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px", backgroundColor: NHColors.primary2 }}
                        ></CompoundButton>
                    </Field>
                    : <Fragment />
                }
            </div >
        </>
    )
}