import { Separator, Stack } from '@fluentui/react';
import { DateRangeType } from '@fluentui/react-calendar-compat';
import { Button, Card, CardHeader, Label, Select, Text, Tooltip } from '@fluentui/react-components';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Calendar24Regular, Info24Regular, People24Regular } from '@fluentui/react-icons';
import { getModelUsage, getUserActivityWithinTimeframe } from 'api';
import { useState } from 'react';
import { cardContainerStyles, cardContentStyles, cardStyles, containerClassName, controlContainerStyles, iconStyles, setGapBetweenHeadersAndDetailsList } from './ManageStatistics.styles';

interface ModelUsage {
    _id: string;
    count: number;
};

const dateRangeOptions = {
    Day: DateRangeType.Day,
    "Work Week": DateRangeType.WorkWeek,
    Week: DateRangeType.Week,
    Month: DateRangeType.Month,
    "Custom": "Custom",
};

const minDate = new Date(2024, 9, 25);
const maxDate = new Date();

const getStartAndEndOfPeriod = (selectedDate: Date, dateRangeType: string): { startDate: Date, endDate: Date } => {
    const startDate = new Date(selectedDate);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(selectedDate);
    endDate.setHours(23, 59, 59, 999);

    switch (dateRangeType) {
        case 'Day':
            break;
        case 'Week':
            const dayOfWeek = selectedDate.getDay();
            startDate.setDate(selectedDate.getDate() - dayOfWeek);
            endDate.setDate(startDate.getDate() + 6);
            break;
        case 'Work Week':
            const dayOfWorkWeek = selectedDate.getDay();
            startDate.setDate(selectedDate.getDate() - (dayOfWorkWeek === 0 ? 6 : dayOfWorkWeek - 1));
            endDate.setDate(startDate.getDate() + 4);
            break;
        case 'Month':
            startDate.setDate(1);
            endDate.setMonth(selectedDate.getMonth() + 1, 0);
            break;
    }
    return { startDate, endDate };
};

export const ManageStatistics = (): JSX.Element => {
    const [dateRangeType, setDateRangeType] = useState<string>("Day");
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [customStartDate, setCustomStartDate] = useState<Date | undefined>(undefined);
    const [customEndDate, setCustomEndDate] = useState<Date | undefined>(undefined);
    const [userActivityCount, setUserActivityCount] = useState<number | undefined>(undefined);
    const [modelUsageCounts, setModelUsageCounts] = useState<ModelUsage[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [customDateError, setCustomDateError] = useState<string | null>(null);

    const handleDateChange = async (date: Date, rangeType: string): Promise<void> => {
        setSelectedDate(date);

        const { startDate, endDate } = getStartAndEndOfPeriod(date, rangeType);
        setStartDate(startDate);
        setEndDate(endDate);

        const activityResponse = await getUserActivityWithinTimeframe({ startDate, endDate });
        const activityCount = Array.isArray(activityResponse) && activityResponse.length > 0
            ? activityResponse[0].uniqueUsers
            : 0;
        setUserActivityCount(activityCount);

        const modelUsageResponse = await getModelUsage({ startDate, endDate });
        setModelUsageCounts(modelUsageResponse || []);
    };

    const handleRangeTypeChange = (ev: any, data: any) => {
        const newRangeType = data.value;
        setDateRangeType(newRangeType);

        if (selectedDate && newRangeType !== "Custom") {
            handleDateChange(selectedDate, newRangeType);
        }
    };

    const handleCustomDateChange = async () => {
        if (customStartDate && customEndDate && customStartDate < customEndDate) {
            const adjustedStartDate = new Date(customStartDate);
            adjustedStartDate.setHours(0, 0, 0, 0);

            const adjustedEndDate = new Date(customEndDate);
            adjustedEndDate.setHours(23, 59, 59, 999);

            setStartDate(adjustedStartDate);
            setEndDate(adjustedEndDate);

            const activityResponse = await getUserActivityWithinTimeframe({ startDate: adjustedStartDate, endDate: adjustedEndDate });
            const activityCount = Array.isArray(activityResponse) && activityResponse.length > 0
                ? activityResponse[0].uniqueUsers
                : 0;
            setUserActivityCount(activityCount);

            const modelUsageResponse = await getModelUsage({ startDate: adjustedStartDate, endDate: adjustedEndDate });
            setModelUsageCounts(modelUsageResponse || []);

            setCustomDateError(null);
        } else {
            setCustomDateError("Start date must be earlier than end date.");
        }
    };

    return (
        <Stack tokens={setGapBetweenHeadersAndDetailsList} className={containerClassName} verticalAlign="start" horizontalAlign="center">
            <div style={controlContainerStyles}>
                <Label style={{ marginTop: '10px' }} htmlFor="select-daterange-id">Select a date range:</Label>

                {dateRangeType !== "Custom"
                    ? <DatePicker
                        placeholder="Select a date..."
                        minDate={minDate}
                        maxDate={maxDate}
                        calendar={{
                            dateRangeType: dateRangeOptions[dateRangeType as keyof typeof dateRangeOptions] as DateRangeType,
                            onSelectDate: (date) => {
                                if (date) {
                                    handleDateChange(date, dateRangeType);
                                }
                            },
                        }}
                    />
                    : <>
                        <Label>Custom Start Date:</Label>
                        <DatePicker
                            placeholder="Select start date..."
                            minDate={minDate}
                            maxDate={maxDate}
                            onSelectDate={(date: any) => setCustomStartDate(date)}
                        />
                        <Label>Custom End Date:</Label>
                        <DatePicker
                            placeholder="Select end date..."
                            minDate={minDate}
                            maxDate={maxDate}
                            onSelectDate={(date: any) => setCustomEndDate(date)}
                        />
                        {customDateError && (
                            <Text style={{ color: 'red', marginTop: '5px' }}>{customDateError}</Text>
                        )}
                        <Button onClick={handleCustomDateChange}>Apply Custom Range</Button>
                    </>
                }

                <Label htmlFor="select-daterange-id">Select a Date Range Type</Label>
                <Select
                    onChange={handleRangeTypeChange}
                    value={dateRangeType}
                    id="select-daterange-id"
                >
                    {Object.keys(dateRangeOptions).map((key) => (
                        <option key={key} value={key}>{key}</option>
                    ))}
                </Select>

                {startDate && endDate && (
                    <Text style={{ marginTop: '10px' }}>
                        Selected Period: {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                    </Text>
                )}
            </div>

            <Separator />

            <div style={cardContainerStyles}>
                <Card style={cardStyles}>
                    <CardHeader
                        header={<Text size={400}><People24Regular /> User Activity</Text>}
                        description={<Text size={300}>Unique users in the selected period</Text>}
                        action={
                            <Tooltip content="This shows the count of unique users within the selected period." relationship={'label'}>
                                <Info24Regular style={iconStyles} />
                            </Tooltip>
                        }
                    />
                    <div style={cardContentStyles}>
                        <Text size={500}>{userActivityCount !== undefined ? userActivityCount : "--"}</Text>
                    </div>
                </Card>

                <Card style={cardStyles}>
                    <CardHeader
                        header={<Text size={400}><Calendar24Regular /> AI Model Usage</Text>}
                        description={<Text size={300}>Model usage in the selected period</Text>}
                        action={
                            <Tooltip content="This shows the usage count of AI models within the selected period." relationship={'label'}>
                                <Info24Regular style={iconStyles} />
                            </Tooltip>
                        }
                    />
                    <div style={cardContentStyles}>
                        {modelUsageCounts.length > 0
                            ? <Stack tokens={{ childrenGap: 4 }}>
                                {modelUsageCounts.map((model) => (
                                    <Text key={model._id} size={300}>
                                        {model._id}: {model.count} uses
                                    </Text>
                                ))}
                            </Stack>
                            : <Text size={300}>No data available</Text>
                        }
                    </div>
                </Card>
            </div>
        </Stack>
    );
};